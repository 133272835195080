export const filterEventsForUpcomingEvents = (allEvents) => {
  const upcomingEvents = allEvents.filter((event) => {
    return new Date(event.event_end_date || event.event_date) > new Date() && event.event_location
  })

  return upcomingEvents
}

export const sortEventsByDate = (events) => {
  return events.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })
}
export const sortEventsByEventDateDescending = (events) => {
  return events.sort((a, b) => {
    return new Date(a.event_date) - new Date(b.event_date)
  })
}
