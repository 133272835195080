import classNames from "classnames"
import * as moment from "moment"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../i18n/translate"
import CalendarIcon from "../../images/icons/calendar.svg"
import LocationIcon from "../../images/icons/location.svg"
import { EventModel } from "../../model/eventModel"
import "./event-information-icons.sass"

const EventInformationIcons = ({ event }) => {
  const ev = new EventModel(event)

  const { i18n } = useTranslation()

  return (
    <div className={classNames("_fp-event-information-icons")}>
      <div className={"_fp-event-information-icons__event-date"}>
        <div className="_fp-event-information-icons__event-date__icon">
          <CalendarIcon />
        </div>
        <p className="_fp-text _fp-event-information-icons__event-date__date">
          <time dateTime={ev.event_date}>
            {moment(ev.event_date).locale(i18n.language).format("L")}
          </time>
          <span> - </span>
          {ev.event_end_date && (
            <time dateTime={ev.event_end_date}>
              {moment(ev.event_end_date).locale(i18n.language).format("L")}
            </time>
          )}
        </p>
      </div>
      {ev.event_location && (
        <div className={"_fp-event-information-icons__event-location"}>
          <div className="_fp-event-information-icons__event-location__icon">
            <LocationIcon />
          </div>
          <a
            target={"_blank"}
            rel={"noreferrer"}
            href={ev.event_location.url}
            className="_fp-text-link _fp-event-information-icons__event-location__location"
          >
            {ev.event_location.getFormattedPlaceName()}
          </a>
        </div>
      )}
    </div>
  )
}

EventInformationIcons.propTypes = {
  event: PropTypes.object.isRequired,
}

export default EventInformationIcons
