import React from "react"

import EventPreview from "../../components/events/event-preview/event-preview"
import VerticalContentRow from "../../components/horizontal-content-row/horizontal-content-row"
import OverflowBehavior from "../../components/overflow-behavior/overflow-behavior"
import SEO from "../../components/seo"
import FullWidthTileContent from "../../components/tile/full-width-tile-content/full-width-tile-content"
import Tile from "../../components/tile/tile"
import TileContainer from "../../components/tile/tile-container/tile-container"
import TileContentSpacer from "../../components/tile/tile-content-spacer/tile-content-spacer"
import UpcomingEventPreview from "../../components/upcoming-event-preview/upcoming-event-preview"
import SideNavView from "../../components/views/side-nav-view/side-nav-view"
import useEvents from "../../hooks/useEvents"
import { useTranslation } from "../../i18n/translate"
import {
  filterEventsForUpcomingEvents,
  sortEventsByDate,
  sortEventsByEventDateDescending,
} from "../../util/event-helpers"

const NewsAndEvents = ({ location, pageContext }) => {
  const { t } = useTranslation()
  const events = useEvents(pageContext.locale)

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("MUNZING_GROUP-NEWS_AND_EVENTS-META_TITLE")}
        descriptionTranslatedString={t(
          "MUNZING_GROUP-NEWS_AND_EVENTS-META_DESCRIPTION",
        )}
      />
      <SideNavView
        titleTranslatedString={t("MUNZING_GROUP-NEWS_AND_EVENTS-META_TITLE")}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          {filterEventsForUpcomingEvents(events).length > 0 && (
            <div className="_fp-col-12">
              <Tile
                titleTranslatedString={t(
                  "MUNZING_GROUP-NEWS_AND_EVENTS-UPCOMING_EVENTS",
                )}
              >
                <FullWidthTileContent>
                  <OverflowBehavior>
                    <TileContainer>
                      <VerticalContentRow>
                        {sortEventsByEventDateDescending(
                          filterEventsForUpcomingEvents(events),
                        ).map((event) => {
                          return <UpcomingEventPreview event={event} />
                        })}
                      </VerticalContentRow>
                    </TileContainer>
                  </OverflowBehavior>
                </FullWidthTileContent>
                <TileContentSpacer size={"small"} />
              </Tile>
            </div>
          )}

          <div className="_fp-col-12">
            <Tile
              titleTranslatedString={t(
                "MUNZING_GROUP-NEWS_AND_EVENTS-META_TITLE",
              )}
            >
              {sortEventsByDate(events).map((event, index, events) => {
                return (
                  <div key={index + "news-events-page"}>
                    <EventPreview event={event} />
                    {index !== events.length - 1 && (
                      <TileContentSpacer size={"large"} />
                    )}
                  </div>
                )
              })}
            </Tile>
          </div>
        </div>
      </SideNavView>
    </>
  )
}

export default NewsAndEvents
