import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./overflow-behavior.sass"

const OverflowBehavior = ({ children, showScrollBar }) => {
  return (
    <div
      className={classNames("_fp-overflow-behavior", {
        "_fp-overflow-behavior--show-scroll-bar": showScrollBar,
      })}
    >
      <div className={"_fp-overflow-behavior__container"}>{children}</div>
    </div>
  )
}

OverflowBehavior.propTypes = {
  showScrollBar: PropTypes.bool,
}

OverflowBehavior.defaultProps = {
  showScrollbar: false,
}

export default OverflowBehavior
