import PropTypes from "prop-types"
import React from "react"
import { stripHtml } from "string-strip-html"

const RichTextPreview = ({ textTranslatedString }) => {
  return <>{stripHtml(textTranslatedString || "").result}</>
}

RichTextPreview.propTypes = {
  textTranslatedString: PropTypes.string.isRequired,
}

export default RichTextPreview
