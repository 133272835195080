import { graphql, useStaticQuery } from "gatsby"

import { EventModel } from "../model/eventModel"

const useEvents = (lang) => {
  return useStaticQuery(graphql`
    query EventsQuery {
      allStrapiEvent {
        edges {
          node {
            available_languages
            id
            strapiId
            text {
              key
            }
            name {
              key
            }
            date
            event_date
            event_end_date
            event_location {
              ...GoogleMapsLocationParams
            }
            video_resources {
              description {
                key
              }
              video_resource_items {
                language
                youtubeId
              }
            }
            image_resources {
              description {
                key
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
            }
            url
          }
        }
      }
    }
  `)
    .allStrapiEvent.edges.filter(
      ({ node }) =>
        !node.available_languages || node.available_languages.includes(lang),
    )
    .map(({ node }) => new EventModel(node))
}

export default useEvents
