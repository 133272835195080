import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as moment from "moment"
import PropTypes from "prop-types"
import React from "react"

import { Locale } from "../../../i18n/locale"
import LocalizedLink from "../../../i18n/localized-link"
import { useTranslation } from "../../../i18n/translate"
import { EventModel } from "../../../model/eventModel"
import VideoPreview from "../../gallery/default-gallery/video-preview/video-preview"
import { GalleryVideoItem } from "../../gallery/model/gallery-item"
import RichTextPreview from "../../rich-text/rich-text-preview"
import "./event-preview.sass"

const EventPreview = ({ event }) => {
  const ev = new EventModel(event)
  const { t, i18n } = useTranslation()
  const currentLocale = new Locale(i18n.language)

  const image =
    ev.image_resources.length > 0
      ? getImage(ev.image_resources[0]?.image?.localFile)
      : undefined

  let videoThumbnail = undefined
  if (ev.video_resources) {
    const preferredLanguage = currentLocale.getLocaleKey()

    for (const resource of ev.video_resources) {
      const galleryItem = resource.getGalleryItem(preferredLanguage)
      if (galleryItem) {
        videoThumbnail = galleryItem
        break
      }
    }
  }

  return (
    <article className={"_fp-event-preview"}>
      <LocalizedLink
        tabIndex={-1}
        to={ev.getPageUrl()}
        className={"_fp-event-preview__thumbnail"}
      >
        {!videoThumbnail && image && (
          <GatsbyImage
            sizes={"10rem"}
            className={"_fp-event-preview__thumbnail__image"}
            image={image}
            alt={t(ev.name.key)}
          />
        )}
        {videoThumbnail && (
          <VideoPreview
            galleryVideo={videoThumbnail}
            className={"_fp-event-preview__thumbnail__image"}
          />
        )}
      </LocalizedLink>
      <p className={"_fp-text _fp-text--small _fp-event-preview__date"}>
        <time dateTime={ev.date}>
          {moment(ev.date).locale(i18n.language).format("LL")}
        </time>
      </p>
      <LocalizedLink tabIndex={-1} to={ev.getPageUrl()}>
        <p className={"_fp-event-preview__title _fp-heading-4"}>
          {t(ev.name.key)}
        </p>
      </LocalizedLink>
      <p className={"_fp-text _fp-event-preview__text-preview"}>
        <RichTextPreview textTranslatedString={t(ev.text.key)} />
      </p>
      <LocalizedLink
        to={ev.getPageUrl()}
        className={"_fp-cta-link _fp-event-preview__link"}
      >
        {t("GLOBAL-READ_MORE")}
      </LocalizedLink>
    </article>
  )
}

EventPreview.propTypes = {
  event: PropTypes.object,
}

export default EventPreview
