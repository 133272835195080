import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../i18n/localized-link"
import { withTranslation } from "../../i18n/translate"
import { EventModel } from "../../model/eventModel"
import EventInformationIcons from "../event-information-icons/event-information-icons"
import "./upcoming-event-preview.sass"

class UpcomingEventPreview extends React.Component {
  render() {
    const ev = new EventModel(this.props.event)
    const { t } = this.props

    return (
      <article>
        <div className={"_fp-upcoming-event-preview"}>
          <LocalizedLink tabIndex={-1} to={ev.getPageUrl()}>
            <p className={"_fp-heading-4 _fp-upcoming-event-preview__title"}>
              {t(ev.name.key)}
            </p>
          </LocalizedLink>
          <span>
            <EventInformationIcons event={ev} />
            <LocalizedLink className={"_fp-cta-link"} to={ev.getPageUrl()}>
              {t("GLOBAL-LEARN_MORE")}
            </LocalizedLink>
          </span>
        </div>
      </article>
    )
  }
}

UpcomingEventPreview.propTypes = {
  event: PropTypes.object,
}

export default withTranslation()(UpcomingEventPreview)
